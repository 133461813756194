const DEFAULT_THRESHOLD = Math.sqrt(1.05 * 0.05) - 0.05;
const RE_HEX = /^(?:[0-9a-f]{3}){1,2}$/i;
export const DEFAULT_BW = {
  black: '#000000',
  white: '#ffffff',
  threshold: DEFAULT_THRESHOLD
};

function padz(str: string, len = 2) {
  return (new Array(len).join('0') + str).slice(-len);
}

export function hexToRgbArray(hexStr: string) {
  let hex = hexStr;

  if (hex.slice(0, 1) === '#') hex = hex.slice(1);
  if (!RE_HEX.test(hex)) throw new Error(`Invalid HEX color: "${hex}"`);
  // normalize / convert 3-chars hex to 6-chars.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  return [
    parseInt(hex.slice(0, 2), 16), // r
    parseInt(hex.slice(2, 4), 16), // g
    parseInt(hex.slice(4, 6), 16) // b
  ];
}

function toRGB(c: any[]) {
  return { r: c[0], g: c[1], b: c[2] };
}

export function toRgbArray(c: any) {
  if (!c) throw new Error('Invalid color value');
  if (Array.isArray(c)) return c;
  return typeof c === 'string' ? hexToRgbArray(c) : [c.r, c.g, c.b];
}

function colorDistance(rgb1: number[], rgb2: number[]): number {
  // Euclidean distance between the two colors
  return Math.sqrt(Math.pow(rgb2[0] - rgb1[0], 2) + Math.pow(rgb2[1] - rgb1[1], 2) + Math.pow(rgb2[2] - rgb1[2], 2));
}

function isNearWhite(hex: string, threshold: number = 25): boolean {
  if (!hex) {
    return false;
  }

  const white = [255, 255, 255]; // RGB for white
  const color = hexToRgbArray(hex);
  const distance = colorDistance(color, white);

  return distance < threshold;
}

function getLuminance(c: string | any[]) {
  let i;
  let x;
  const a = []; // so we don't mutate
  for (i = 0; i < c.length; i++) {
    x = c[i] / 255;
    a[i] = x <= 0.03928 ? x / 12.92 : Math.pow((x + 0.055) / 1.055, 2.4);
  }
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

export function invertToBW(colorArr: string | any[], bw: any, asArr?: boolean | undefined) {
  const options = bw === true ? DEFAULT_BW : { ...DEFAULT_BW, ...bw };
  // eslint-disable-next-line no-nested-ternary
  return getLuminance(colorArr) > options.threshold
    ? asArr
      ? hexToRgbArray(options.black)
      : options.black
    : asArr
    ? hexToRgbArray(options.white)
    : options.white;
}

export function isLight(color: string) {
  return getLuminance(toRgbArray(color)) > DEFAULT_BW.threshold;
}

function invertColor(color: string, bw = false) {
  if (typeof color === 'string' && color.toUpperCase() === 'TRANSPARENT') return '#000000';
  const colorArr = toRgbArray(color);
  if (bw) return invertToBW(colorArr, bw);
  return `#${colorArr.map(c => padz((255 - c).toString(16))).join('')}`;
}

export const getDarkerColor = (color: string, darkerPercent: number) => {
  const rgb = [color.substring(1, 3), color.substring(3, 5), color.substring(5, 7)];
  return `rgb(${rgb.map(c => parseInt(`${parseInt(c, 16) * darkerPercent}`)).join()})`;
};

export function correctHexColor(hexStr?: string, hexDefault = '#000000', hexTransparentAlternative?: string) {
  let hexColor = hexStr;
  if (hexColor === '' || hexColor === null || hexColor === undefined) hexColor = hexDefault;
  if (hexColor.toUpperCase() === 'TRANSPARENT') hexColor = hexTransparentAlternative || hexDefault;
  if (hexColor.slice(0, 1) !== '#') hexColor = `#${hexColor}`;
  if (hexColor.length === 4) {
    hexColor = hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3];
  }
  return hexColor;
}

function asRGB(color: any, bw: any) {
  const colorArr = toRgbArray(color);
  const list = bw ? invertToBW(colorArr, bw, true) : colorArr.map(c => 255 - c);
  return toRGB(list);
}

function asRgbArray(color: any, bw: any) {
  const colorArr = toRgbArray(color);
  return bw ? invertToBW(colorArr, bw, true) : colorArr.map(c => 255 - c);
}

function mustHaveBorderForBackground(bgColor = '') {
  return (
    bgColor.toUpperCase() === 'TRANSPARENT' || bgColor.toUpperCase() === '#FFF' || bgColor.toUpperCase() === '#FFFFFF'
  );
}
const defaultThreshold = DEFAULT_THRESHOLD;

const asRgbObject = asRGB;

export const getTicketBackgroundTextColors = (schoolPrimaryColor?: string, defaultColor = '#000000') => {
  const corrected = correctHexColor(schoolPrimaryColor, defaultColor, '#FFFFFF');
  const primaryColor = corrected === '#FFFFFF' ? defaultColor : corrected;
  const secondaryColor = invertColor(primaryColor, true);

  return { primaryColor, secondaryColor };
};

// Takes a hex value (ex: #eb4034 or eb4034) and consistently returns it with a # prefix
export const formatHexString = (hex: string) => {
  return '#' + hex.replace('#', '');
};

export const addHexOpacity = (hex: string | undefined, alpha: number) => {
  if (!hex) return '#ffffff';

  return `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, '0')}`;
};

export const componentFromStr = (numStr: string, percent: string) => {
  const num = Math.max(0, parseInt(numStr, 10));
  return percent ? Math.floor((255 * Math.min(100, num)) / 100) : Math.min(255, num);
};

const rgbToHex = (rgb: string) => {
  const rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
  let result,
    r,
    g,
    b,
    hex = '';
  if ((result = rgbRegex.exec(rgb))) {
    r = componentFromStr(result[1], result[2]);
    g = componentFromStr(result[3], result[4]);
    b = componentFromStr(result[5], result[6]);

    hex = '#' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  return hex;
};

export {
  invertColor,
  asRgbObject,
  defaultThreshold,
  asRgbArray,
  asRGB,
  mustHaveBorderForBackground,
  isNearWhite,
  rgbToHex
};
