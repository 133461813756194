import React, { createContext, useContext, useState } from 'react';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks/useMediaQuery';
import { Platform } from 'react-native';
export * from './SchoolSearchAnimationProvider';

/**
 * Provider for SearchPage
 */

export type SearchPageContextProps = {
  searchFocused?: boolean;
  toggleSearchFocused: (state: boolean | undefined) => void;
  active?: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  searchInputRef: React.MutableRefObject<HTMLInputElement | undefined> | undefined;
  focusSearchInput: (focused?: boolean) => void;
  footerVisible: boolean;
  setFooterVisible: (visiable: boolean) => void;
  searchResultHeight: number;
  handlePressOutSideSearch: () => void;
};

export const SearchPageContext = createContext<SearchPageContextProps>({
  searchFocused: false,
  toggleSearchFocused: (_state: boolean | undefined) => {},
  active: false,
  setActive: (_value: React.SetStateAction<boolean>) => {},
  searchInputRef: undefined,
  focusSearchInput: () => {},
  footerVisible: true,
  setFooterVisible: () => {},
  searchResultHeight: 410,
  handlePressOutSideSearch: () => {}
});

export type SearchPageProviderProps = {
  children: React.ReactNode;
};

export const SearchPageProvider = ({ children }: SearchPageProviderProps) => {
  const [searchFocused, setSearchFocused] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [footerVisible, setFooterVisible] = useState<boolean>(true);
  const [searchResultHeight, setSearchResultHeight] = useState<number>(410);
  const searchInputRef = React.useRef<HTMLInputElement>();
  const { isMobileApp, height: windowHeight, isWebApp, isAndroid, isIOS } = useMediaQuery();
  const toggleSearchFocused = (state: boolean | undefined) => {
    if (typeof state === 'boolean') {
      if (state === false && isWebApp) {
        setTimeout(() => {
          document.getElementById('__next')?.scrollIntoView({ behavior: 'smooth' });
        }, 1100);
      }
      setSearchFocused(state);
    } else setSearchFocused(prev => !prev);
  };

  const focusSearchInput = (focused?: boolean) => {
    if (focused === false) searchInputRef?.current?.blur();
    else searchInputRef?.current?.focus();
  };

  const handlePressOutSideSearch = () => {
    if (searchFocused && Platform.OS !== 'web') {
      toggleSearchFocused && toggleSearchFocused(false);
      setActive(false);
      focusSearchInput(false);
    }
  };

  React.useEffect(() => {
    const offset = (function getOffset() {
      if (isIOS) return 232;
      else if (isAndroid) return 178;
      return 152;
    })();
    const desktopHeight = 410;
    const h = isMobileApp ? windowHeight - offset : desktopHeight;
    setSearchResultHeight(h);
  }, [isMobileApp]);

  return (
    <SearchPageContext.Provider
      value={{
        searchFocused,
        toggleSearchFocused,
        active,
        setActive,
        searchInputRef,
        focusSearchInput,
        footerVisible,
        setFooterVisible,
        searchResultHeight,
        handlePressOutSideSearch
      }}
    >
      {children}
    </SearchPageContext.Provider>
  );
};

export type UseSearchPageContext = () => SearchPageContextProps;

export const useSearchPageContext: UseSearchPageContext = () => {
  return useContext(SearchPageContext);
};
