import React, { createContext, useContext, useEffect } from 'react';
import { DrawerPosition, DrawerRegistry } from './drawerUtil';
import { TouchableOpacity, View } from 'react-native';
import { RightDrawer } from './RightDrawer';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { LeftDrawer } from './LeftDrawer';
import { BottomDrawer } from './BottomDrawer';

interface iDrawerContext {
  closeDrawer: (drawerId: string) => void;
  closeAllDrawers: () => void;
  openDrawer: (drawerId: string) => void;
  toggleDrawer: (drawerId: string) => void;
  registerDrawer: (drawerId: string, registryInfo: DrawerRegistry) => void;
  unregisterDrawer: (drawerId: string) => void;
  isAnyDrawerOpen: boolean;
}

export const DrawerContext = createContext<iDrawerContext>({
  closeDrawer: () => {},
  closeAllDrawers: () => {},
  openDrawer: () => {},
  toggleDrawer: () => {},
  registerDrawer: () => {},
  unregisterDrawer: () => {},
  isAnyDrawerOpen: false
});

const getOpenDrawerId = (registry: { [key: string]: DrawerRegistry }, position: DrawerPosition) => {
  for (const [drawerId, data] of Object.entries(registry)) {
    if (data.position === position && data.isOpen) {
      return drawerId;
    }
  }
};
interface DrawerContentProps {
  drawerId: string;
  content: React.JSX.Element;
  position: DrawerPosition;
  drawerSize?: number;
  dependentData?: any;
  closeFunction?: () => void;
  isFullScreen?: boolean;
}

export const DrawerContent = ({
  drawerId,
  content,
  position,
  drawerSize,
  dependentData,
  closeFunction,
  isFullScreen
}: DrawerContentProps) => {
  const { registerDrawer, unregisterDrawer } = useDrawerContext();
  useEffect(() => {
    registerDrawer(drawerId, { content, position, isOpen: false, drawerSize, closeFunction, isFullScreen });
    return () => {
      unregisterDrawer(drawerId);
    };
  }, [dependentData]);
  return null;
};

interface DrawerProviderProps {
  children: React.ReactNode;
}

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [drawerRegistry, setDrawerRegistry] = React.useState<{ [key: string]: DrawerRegistry }>({});
  const { isMobile, isNativeApp } = useMediaQuery();

  const openDrawer = (drawerId: string) => {
    setDrawerRegistry(currentRegistry => {
      const newRegistry = { ...currentRegistry };
      if (newRegistry[drawerId]) {
        newRegistry[drawerId].isOpen = true;
      }
      return newRegistry;
    });
  };

  const closeDrawer = (drawerId: string) => {
    setDrawerRegistry(currentRegistry => {
      const newRegistry = { ...currentRegistry };
      if (newRegistry[drawerId]) {
        newRegistry[drawerId].isOpen = false;
        newRegistry[drawerId].closeFunction && newRegistry[drawerId].closeFunction?.();
      }
      return newRegistry;
    });
  };

  const closeAllDrawers = () => {
    setDrawerRegistry(currentRegistry => {
      const newRegistry = { ...currentRegistry };
      for (const [key, value] of Object.entries(newRegistry)) {
        if (key) value.isOpen = false;
      }
      return newRegistry;
    });
  };

  const toggleDrawer = (drawerId: string) => {
    setDrawerRegistry(currentRegistry => {
      const newRegistry = { ...currentRegistry };
      newRegistry[drawerId].isOpen = !newRegistry[drawerId].isOpen;
      return newRegistry;
    });
  };

  const registerDrawer = (drawerId: string, registryInfo: DrawerRegistry) => {
    setDrawerRegistry(currentRegistry => {
      return {
        ...currentRegistry,
        [drawerId]: registryInfo
      };
    });
  };

  const unregisterDrawer = (drawerId: string) => {
    setDrawerRegistry(currentRegistry => {
      const newRegistry = { ...currentRegistry };
      delete newRegistry[drawerId];
      return newRegistry;
    });
  };

  const rightDrawerId = getOpenDrawerId(drawerRegistry, 'right');
  const leftDrawerId = getOpenDrawerId(drawerRegistry, 'left');
  const bottomDrawerId = getOpenDrawerId(drawerRegistry, 'bottom');
  const isAnyDrawerOpen = Object.values(drawerRegistry).filter(drawerRegistry => drawerRegistry.isOpen).length > 0;

  const DrawerContextValue = {
    closeDrawer,
    closeAllDrawers,
    openDrawer,
    toggleDrawer,
    registerDrawer,
    unregisterDrawer,
    isAnyDrawerOpen
  };

  return (
    <DrawerContext.Provider value={DrawerContextValue}>
      <AppWrapper lowerOpacity={isNativeApp && isAnyDrawerOpen}>{children}</AppWrapper>
      {rightDrawerId ? (
        <RightDrawerWrapper
          accessible={false}
          activeOpacity={1}
          isMobile={isMobile.toString()}
          isNative={isNativeApp.toString()}
          onPress={closeAllDrawers}
        >
          <RightDrawer
            content={drawerRegistry[rightDrawerId].content}
            drawerOpen={drawerRegistry[rightDrawerId].isOpen}
            drawerSize={drawerRegistry[rightDrawerId].drawerSize}
          />
        </RightDrawerWrapper>
      ) : null}
      {rightDrawerId && drawerRegistry[rightDrawerId].isFullScreen ? (
        <RightDrawerWrapperFullScreen
          accessible={false}
          activeOpacity={1}
          isMobile={isMobile.toString()}
          isNative={isNativeApp.toString()}
          onPress={closeAllDrawers}
        >
          <RightDrawer
            content={drawerRegistry[rightDrawerId].content}
            drawerOpen={drawerRegistry[rightDrawerId].isOpen}
            drawerSize={drawerRegistry[rightDrawerId].drawerSize}
            isFullScreen={drawerRegistry[rightDrawerId].isFullScreen}
          />
        </RightDrawerWrapperFullScreen>
      ) : null}
      {leftDrawerId ? (
        <LeftDrawerWrapper
          accessible={false}
          activeOpacity={1}
          isMobile={isMobile.toString()}
          isNative={isNativeApp.toString()}
          onPress={closeAllDrawers}
        >
          <LeftDrawer
            content={drawerRegistry[leftDrawerId].content}
            drawerOpen={drawerRegistry[leftDrawerId].isOpen}
            drawerSize={drawerRegistry[leftDrawerId].drawerSize}
          />
        </LeftDrawerWrapper>
      ) : null}
      {bottomDrawerId ? (
        <BottomDrawerWrapper
          accessible={false}
          activeOpacity={1}
          isMobile={isMobile.toString()}
          isNative={isNativeApp.toString()}
          onPress={closeAllDrawers}
        >
          <BottomDrawer
            content={drawerRegistry[bottomDrawerId].content}
            drawerOpen={drawerRegistry[bottomDrawerId].isOpen}
            drawerSize={drawerRegistry[bottomDrawerId].drawerSize}
          />
        </BottomDrawerWrapper>
      ) : null}
    </DrawerContext.Provider>
  );
};

const AppWrapper = styled(View)`
  ${styles.appWrapper}
`;
const RightDrawerWrapper = styled(TouchableOpacity)`
  ${styles.rightDrawerWrapper}
`;
const RightDrawerWrapperFullScreen = styled(TouchableOpacity)`
  ${styles.rightDrawerWrapperFullScreen}
`;
const LeftDrawerWrapper = styled(TouchableOpacity)`
  ${styles.leftDrawerWrapper}
`;
const BottomDrawerWrapper = styled(TouchableOpacity)`
  ${styles.bottomDrawerWrapper}
`;

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  return context;
};
