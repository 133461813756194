import React, { useEffect, useRef } from 'react';
import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils';
import { SegmentContextType, SegmentProviderProps } from './types';
import { SegmentContext } from './SegmentContext';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { withOneTrust } from '@segment/analytics-consent-wrapper-onetrust';
import { getOneTrustGlobal } from '@gf/cross-platform-lib/hooks/useConsent';

const appConfig = GetCurrentApplicationConfiguration();

const SegmentProvider = ({ children }: SegmentProviderProps) => {
  const [isReady, setIsReady] = React.useState(false);
  const [segmentClient, setSegmentClient] = React.useState<any>(false);
  const [isError, setIsError] = React.useState(false);
  const onConsentChangedEventInitRef = useRef(false);
  const oneTrustGlobal = getOneTrustGlobal();

  useEffect(() => {
    if (!onConsentChangedEventInitRef.current) {
      oneTrustGlobal?.OnConsentChanged(() => {
        window.location.reload();
      });
      onConsentChangedEventInitRef.current = true;
    }

    return () => {
      onConsentChangedEventInitRef.current = false;
    };
  }, [oneTrustGlobal]);

  useEffect(() => {
    import('@segment/analytics-next')
      .then(module => {
        const segmentClient = new module.AnalyticsBrowser();
        if (oneTrustGlobal) {
          withOneTrust(segmentClient, { consentModel: () => 'opt-out' })
            .load({
              cdnURL: appConfig.settings.tracking.segment.cdn,
              writeKey: appConfig.settings.tracking.segment.web.writeKey
            })
            .then(() => {
              setIsReady(true);
              setSegmentClient(segmentClient);
            })
            .catch(e => {
              setIsError(true);
              recordError(e, {
                originatingFunction: 'SegmentProvider',
                customMessage: 'Segment failed to load with OneTrust - Failed to initialize the Segment client',
                errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
                data: {
                  cdnURL: appConfig.settings.tracking.segment.cdn
                }
              });
            });
        } else {
          segmentClient
            .load({
              cdnURL: appConfig.settings.tracking.segment.cdn,
              writeKey: appConfig.settings.tracking.segment.web.writeKey
            })
            .then(() => {
              setIsReady(true);
              setSegmentClient(segmentClient);
            })
            .catch(e => {
              setIsError(true);
              recordError(e, {
                originatingFunction: 'SegmentProvider',
                customMessage: 'Segment failed to load without OneTrust - Failed to initialize the Segment client',
                errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
                data: {
                  cdnURL: appConfig.settings.tracking.segment.cdn
                }
              });
            });
        }
      })
      .catch(e => {
        setIsError(true);
        recordError(e, {
          originatingFunction: 'SegmentProvider',
          customMessage: 'Segment failed to load - Failed to import the Segment analytics module',
          errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
          data: {
            cdnURL: appConfig.settings.tracking.segment.cdn
          }
        });
      });
  }, [oneTrustGlobal]);

  const handleCustomTrack = async (eventName: string, payload?: any) => {
    await segmentClient.track(eventName, payload);
  };

  const handleTrackPageView = async (name: string, properties?: any) => {
    await segmentClient.page(name, properties);
  };

  const handleTrackIdentify = async (traits?: any) => {
    await segmentClient.identify(undefined, { ...(traits || {}), user_id: undefined });
  };

  const handleReset = async () => {
    await segmentClient.reset();
  };
  const contextValue: SegmentContextType = {
    track: handleCustomTrack,
    identify: handleTrackIdentify,
    pageView: handleTrackPageView,
    reset: handleReset
  };

  if (isError) {
    return children;
  }

  return <SegmentContext.Provider value={contextValue}>{isReady && children}</SegmentContext.Provider>;
};

export { SegmentProvider };
