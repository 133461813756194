import React from 'react';
import { styles } from './FundraiserUpsellContent.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { HTMLDescription } from '@gf/cross-platform-lib/components/HTMLDescription/HTMLDescription';

const Container = styled.View`
  ${styles.container}
`;
const Title = styled.Text`
  ${styles.title}
`;
const SchoolName = styled.Text`
  ${styles.schoolName}
`;
const DescriptionContainer = styled.ScrollView`
  ${styles.descriptionContainer}
`;

export type FundraiserUpsellContentProps = {
  schoolName?: string;
  eventTitle?: string;
  eventDescription?: string;
};

export const FundraiserUpsellContent = ({ schoolName, eventTitle, eventDescription }: FundraiserUpsellContentProps) => {
  return (
    <Container>
      <SchoolName>{schoolName}</SchoolName>
      <Title numberOfLines={1}>{eventTitle}</Title>
      <DescriptionContainer>
        <HTMLDescription description={eventDescription} />
      </DescriptionContainer>
    </Container>
  );
};
