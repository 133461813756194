import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { StyledText } from '@gf/cross-platform-lib/components/StyledText';

const ContentItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row>
      <StyledText>{`–`}</StyledText>
      {children}
    </Row>
  );
};

export const ImportantInformationSection = ({ showTransferLimitInfo = true }: { showTransferLimitInfo?: boolean }) => {
  return (
    <Wrapper>
      <Title typeToken='body01SemiBold'>Important information</Title>
      {showTransferLimitInfo ? (
        <ContentItem>
          <InfoText typeToken='bodyCompact01'>
            Transfer recipients (who you’re transferring this pass to){' '}
            <StyledText typeToken='bodyCompact01SemiBold'>cannot</StyledText> transfer a mobile pass to others
          </InfoText>
        </ContentItem>
      ) : (
        <></>
      )}
      <ContentItem>
        <InfoText typeToken='bodyCompact01'>
          You can cancel your pass transfer up until the transfer recipient accepts
        </InfoText>
      </ContentItem>
      <ContentItem>
        <InfoText typeToken='bodyCompact01'>
          Accepted mobile pass transfers are <StyledText typeToken='bodyCompact01SemiBold'>final</StyledText>
        </InfoText>
      </ContentItem>
    </Wrapper>
  );
};

const Wrapper = styled.View``;
const Title = styled(StyledText)`
  margin-bottom: 8px;
`;
const InfoText = styled(StyledText)`
  margin-left: 4px;
`;
const Row = styled.View`
  flex-direction: row;
  margin-bottom: 4px;
`;
