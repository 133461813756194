import { Platform } from 'react-native';
import {
  GetCurrentApplicationConfiguration,
  Method,
  debouncedPromise,
  GetCurrentEnvironmentName
} from '@gf/cross-platform-lib/utils';
import { privateSafeFetch, safeFetch } from '../utils';
import { OrderDTO, IOrderRequest, OrderIntentDTO } from '@gf/cross-platform-lib/models';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { EventOrderHistory } from '@gf/cross-platform-lib/interfaces';
import { getRecaptchaToken } from '@gf/cross-platform-lib/utils/recaptcha';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { Fan } from '@gf/cross-platform-lib/models';
import isEmpty from 'lodash/isEmpty';
import { mapToOrderHistoryData } from '../../app/Layout/profile';

const appConfig = GetCurrentApplicationConfiguration();
const notificationsUrl = appConfig.api.notifications.getUrl!();

export const getOrder = async (orderAccessToken: string, showLayoutOnError = true) => {
  const url = appConfig.api.orders.getUrlForMethodAndId!(Method.GET, orderAccessToken);
  return privateSafeFetch<OrderDTO>(url!, {}, { showLayoutOnError, redirect404: false }).then(resp => {
    if (resp.error) {
      throw resp.error;
    }
    return resp;
  });
};

type Notification = {
  receiptPhone: string;
  receiveSMSNotifications: boolean;
};

export const sendOrderNotifications = async (order: OrderDTO) => {
  const accessToken = order.accessToken;
  const notificationsUrl = `${appConfig.api.notifications.baseUrl}/orders/notifications`;
  try {
    let res = await privateSafeFetch<Notification>(notificationsUrl, {}, { redirect404: false });
    const { receiptPhone, receiveSMSNotifications } = res.data || {};

    if (!receiptPhone || !receiveSMSNotifications) {
      // Don't set notifications for order if user has not opted in
      return;
    }

    await safeFetch(
      `${notificationsUrl}/${accessToken}`,
      {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          receiptPhone,
          receiveSMSNotifications
        })
      },
      { redirect404: false }
    );
  } catch (e: any) {
    // Don't let notifications failure hold up a payment
    recordError(e, {
      originatingFunction: 'orderApis-sendOrderNotifications',
      customMessage: 'Failed to send order notifications after an order was placed. Payment was not affected.',
      errorGroup: NEW_RELIC_ERROR_GROUPS.PaymentAndOrders,
      data: { order, accessToken }
    });
  }
};

export const getEncryptedStrings = async (unencryptedStrings: string[]) => {
  const url = appConfig.api.encrypt.baseUrl;
  return safeFetch<{ [key: string]: string }>(url!, {
    method: 'POST',
    body: JSON.stringify(unencryptedStrings)
  });
};

export const createOrder = async (orderRequest: IOrderRequest, shouldBypassRecaptcha: boolean) => {
  const url = appConfig.api.ordersV2.baseUrl;
  const platform = Platform.OS;
  const envName = GetCurrentEnvironmentName();
  const userEmail = Fan.getInstance().email || orderRequest.purchaserEmail;

  let clientPlatform;
  if (envName === 'perf') {
    clientPlatform = 'perf';
  } else if (shouldBypassRecaptcha) {
    clientPlatform = 'bypass';
  } else {
    clientPlatform = platform;
  }

  const headers = new Headers({
    'Content-Type': 'application/json',
    'User-Email': userEmail || '',
    'GoFan-Client-Platform': clientPlatform,
    'GoFan-App-Version': appConfig.appVersion
  });

  if (!shouldBypassRecaptcha) {
    const recaptchaToken = await getRecaptchaToken('create_order');

    if (recaptchaToken) {
      headers.append('GOFAN_CAPTCHA_TOKEN', recaptchaToken);
    } else if (platform === 'web') {
      recordError('Recaptcha token not found', {
        customMessage: `Recaptcha token failed to be retrieved during order creation`,
        originatingFunction: 'orderApis->createOrder',
        data: { orderRequest },
        errorGroup: NEW_RELIC_ERROR_GROUPS.Recaptcha
      });
    }
  }

  return await privateSafeFetch<OrderIntentDTO>(url!, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(orderRequest),
    requiredAuthHeader: !orderRequest.guestType
  });
};

export const createFreeOrder = async (orderRequest: IOrderRequest, shouldBypassRecaptcha: boolean) => {
  const orderV1Url = `${appConfig.api.orderservice.baseUrl}/orderservice/v2/orders`;
  const platform = Platform.OS;
  const envName = GetCurrentEnvironmentName();
  const recaptchaToken = await getRecaptchaToken('create_free_order');
  const userEmail = Fan.getInstance().email;

  const clientPlatform = envName === 'perf' || shouldBypassRecaptcha ? 'perf' : platform;

  const headers = new Headers({
    'Content-Type': 'application/json',
    'User-Email': userEmail || '',
    'GoFan-Client-Platform': clientPlatform,
    'GoFan-App-Version': appConfig.appVersion
  });

  if (recaptchaToken) {
    headers.append('GOFAN_CAPTCHA_TOKEN', recaptchaToken);
  } else if (platform === 'web') {
    recordError('Recaptcha token not found', {
      customMessage: `Recaptcha token failed to be retrieved during free order creation`,
      originatingFunction: 'orderApis->createFreeOrder',
      data: { orderRequest },
      errorGroup: NEW_RELIC_ERROR_GROUPS.Recaptcha
    });
  }

  return await privateSafeFetch<{ order: OrderDTO }>(orderV1Url!, {
    method: 'POST',
    body: JSON.stringify(orderRequest),
    headers: headers,
    requiredAuthHeader: !orderRequest.guestType
  });
};

export const updateOrder = async (orderRequest: IOrderRequest) => {
  return await privateSafeFetch<OrderIntentDTO>(appConfig.api.ordersV2.getUrl!(Method.PUT)!, {
    method: 'PUT',
    body: JSON.stringify({ ...orderRequest }),
    requiredAuthHeader: !orderRequest.guestType
  });
};

export const debouncedUpdateOrder = debouncedPromise(updateOrder, 750);

export const updateNotifications = async (queryParams: { [key: string]: any }, shouldBypassRecaptcha: boolean) => {
  const platform = Platform.OS;
  const envName = GetCurrentEnvironmentName();
  const recaptchaToken = await getRecaptchaToken('update_notifications');
  const userEmail = Fan.getInstance().email;

  const clientPlatform = envName === 'perf' || shouldBypassRecaptcha ? 'perf' : platform;

  const headers = new Headers({
    'Content-Type': 'application/json',
    'User-Email': userEmail || '',
    'GoFan-Client-Platform': clientPlatform
  });

  if (recaptchaToken) {
    headers.append('GOFAN_CAPTCHA_TOKEN', recaptchaToken);
  } else if (platform === 'web') {
    recordError('Recaptcha token not found', {
      customMessage: `Recaptcha token failed to be retrieved during notification update`,
      originatingFunction: 'orderApis->updateNotifications',
      data: { queryParams },
      errorGroup: NEW_RELIC_ERROR_GROUPS.Recaptcha
    });
  }

  return privateSafeFetch(notificationsUrl!, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify({
      ...queryParams
    })
  });
};

export const updateNotificationsByAccessToken = async (
  accessToken: string,
  queryParams: { [key: string]: any },
  shouldBypassRecaptcha: boolean
) => {
  const platform = Platform.OS;
  const envName = GetCurrentEnvironmentName();
  const recaptchaToken = await getRecaptchaToken('update_notifications_by_access_token');
  const userEmail = Fan.getInstance().email;

  const clientPlatform = envName === 'perf' || shouldBypassRecaptcha ? 'perf' : platform;

  const headers = new Headers({
    'Content-Type': 'application/json',
    'User-Email': userEmail || '',
    'GoFan-Client-Platform': clientPlatform
  });

  const url = `${notificationsUrl}/${accessToken}`;

  if (recaptchaToken) {
    headers.append('GOFAN_CAPTCHA_TOKEN', recaptchaToken);
  } else if (platform === 'web') {
    recordError('Recaptcha token not found', {
      customMessage: `Recaptcha token failed to be retrieved during notification update`,
      originatingFunction: 'orderApis->updateNotifications',
      data: { queryParams },
      errorGroup: NEW_RELIC_ERROR_GROUPS.Recaptcha
    });
  }
  return privateSafeFetch(url!, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(queryParams)
  });
};

export const getNotificationsByAccessToken = async (accessToken: string) => {
  return safeFetch<Notification>(`${notificationsUrl}/${accessToken}`);
};

export const getNotifications = async () => {
  return privateSafeFetch<Notification>(notificationsUrl!, { requiredAuthHeader: true }, { redirect404: false });
};

type OrderHistoryResponse = {
  data: EventOrderHistory[];
  meta: { name: string; type: string }[];
  rows_before_limit_at_least: number;
  rows: number;
  statistics: { bytes_read: number; elapsed: number; rows_read: number };
};

export const getOrderHistories = async ({
  pageSize = 1000,
  end_time,
  start_time
}: {
  pageSize?: number;
  end_time?: string;
  start_time?: string;
}) => {
  const url_end_time = isEmpty(end_time) ? '' : `&end_time=${encodeURIComponent(end_time!)}`;
  const url_start_time = isEmpty(start_time) ? '' : `&start_time=${encodeURIComponent(start_time!)}`;
  const url = `${appConfig.api.ordersV2.baseUrl}/order-history?page_size=${pageSize}${url_end_time}${url_start_time}`;

  return privateSafeFetch<OrderHistoryResponse>(url!, { requiredAuthHeader: true }, { redirect404: false });
};

export const getAllOrderHistories = async ({
  pageSize,
  end_time,
  start_time
}: {
  pageSize?: number;
  end_time?: string;
  start_time?: string;
}) => {
  const res = await getOrderHistories({ pageSize, end_time, start_time });

  if (res.data === null || res.data?.rows_before_limit_at_least === 0) {
    return { ...res, data: undefined };
  } else if (res.data?.rows_before_limit_at_least > res.data.rows) {
    const allData = await getOrderHistories({
      pageSize: res.data.rows_before_limit_at_least || 1000,
      end_time,
      start_time
    });
    if (allData.data === null || allData.data?.rows_before_limit_at_least === 0) {
      return { ...allData, data: undefined };
    }
    return {
      ...allData,
      data: mapToOrderHistoryData(allData.data?.data)
    };
  } else {
    return {
      ...res,
      data: mapToOrderHistoryData(res.data?.data)
    };
  }
};
