import React from 'react';
import styled from 'styled-components';
import { styles } from './HTMLDescription.styles';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

export type EventDescriptionProps = {
  description: string;
};

const DescriptionHTML = styled.div`
  ${styles.descriptionHTML}
`;

export const HTMLDescription = ({ description }: EventDescriptionProps) => {
  const contentClickHandler = (e: any) => {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    e.preventDefault();

    if (targetLink.getAttribute('href') && targetLink.getAttribute('href').slice(0, 4) === 'http') {
      window.open(targetLink.href);
    }
  };

  return (
    <>
      <Svg height='2px' width='100%' style={{ position: 'absolute', top: 0, zIndex: 1 }}>
        <Defs>
          <LinearGradient id='upsell-description-top-grad' x1='0%' y1='0%' x2='0%' y2='25%'>
            <Stop offset='0' stopColor={`#F6F6FA`} stopOpacity={1} />
            <Stop offset='1' stopColor={`#F6F6FA`} stopOpacity={0} />
          </LinearGradient>
        </Defs>
        <Rect width='100%' height='100%' fill='url(#upsell-description-top-grad)' />
      </Svg>
      <DescriptionHTML onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: description }} />
      <Svg height='12px' width='100%' style={{ bottom: 0, zIndex: 1, position: 'absolute' }}>
        <Defs>
          <LinearGradient id='upsell-description-bottom-grad' x1='0%' y1='0%' x2='0%' y2='50%'>
            <Stop offset='0' stopColor={`#F6F6FA`} stopOpacity={0} />
            <Stop offset='1' stopColor={`#F6F6FA`} stopOpacity={1} />
          </LinearGradient>
        </Defs>
        <Rect width='100%' height='100%' fill='url(#upsell-description-bottom-grad)' />
      </Svg>
    </>
  );
};

export default HTMLDescription;
