import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import customParseFormat from 'dayjs/plugin/customParseFormat'; // dependent on utc plugin
import { DEFAULT_FORMAT, DEFAULT_TIMEZONE, FULL_DATE_TIME_FORMAT } from '../constants';
import isEmpty from 'lodash/isEmpty';
import { SIMPLE_DATE_FORMAT } from '@gf/cross-platform-lib/utils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const useDate = (
  dateTime: string,
  timezone: string = DEFAULT_TIMEZONE,
  inputFormat: string = DEFAULT_FORMAT,
  outputFormat: string = FULL_DATE_TIME_FORMAT
) => {
  const isEmptyDate = isEmpty(dateTime);
  const [dateString, setDateString] = useState<string>('');
  const [date, setDate] = useState<dayjs.Dayjs | null>(isEmptyDate ? null : dayjs(dateTime, inputFormat).tz(timezone));
  const [nowIsPastDate, setNowIsPastDate] = useState<boolean | null>(false);

  const isPastDate = (other: string, otherTimezone?: string): boolean | null => {
    const otherDate = dayjs(other, inputFormat).tz(otherTimezone);

    if (isEmptyDate) return null;
    return otherDate.isAfter(date);
  };

  const isBeforeDate = (other: string, otherTimezone?: string): boolean | null => {
    const otherDate = dayjs(other, inputFormat).tz(otherTimezone);

    if (isEmptyDate) return null;
    return otherDate.isBefore(date);
  };

  useEffect(() => {
    const currentDate = isEmptyDate ? null : dateTime && timezone && dayjs(dateTime, inputFormat).tz(timezone);

    const currentDateString = isEmptyDate
      ? ''
      : dateTime && timezone && dayjs(dateTime, inputFormat).tz(timezone).format(outputFormat).toString();

    currentDate && setDate(currentDate);
    currentDateString && setDateString(currentDateString);
  }, [dateTime, timezone, outputFormat]);

  useEffect(() => {
    const now = dayjs();
    const endDate = isEmptyDate ? null : dayjs(dateTime, DEFAULT_FORMAT);

    setNowIsPastDate(endDate === null ? null : now.isAfter(endDate));
  }, [dateTime]);

  return { date, dateString, nowIsPastDate, isBeforeDate, isPastDate };
};

type UseDateRangeResult = {
  getDate: (dateTimeString: string, timezone: string, format: string) => dayjs.Dayjs;
  isSameYear: boolean;
  isSameDay: boolean;
  isMultiDay: boolean;
  isWithinRange: (date: dayjs.Dayjs) => boolean;
  isBeforeRange: (date: dayjs.Dayjs) => boolean;
  isAfterRange: (date: dayjs.Dayjs) => boolean;
  isOutsideRange: (date: dayjs.Dayjs) => boolean;
  currentlyWithinRange: () => boolean;
};

export const getDateRange = (
  startDateTime: string,
  endDateTime: string,
  timezone: string = DEFAULT_TIMEZONE,
  inputFormat: string = DEFAULT_FORMAT,
  isAllDayEvent: boolean = false
): UseDateRangeResult => {
  let startDate = isAllDayEvent
    ? dayjs(dayjs(startDateTime).tz(timezone).format(SIMPLE_DATE_FORMAT))
    : dayjs(startDateTime, inputFormat).tz(timezone);
  let endDate = isAllDayEvent
    ? dayjs(dayjs(endDateTime).tz(timezone).format(SIMPLE_DATE_FORMAT)).add(1, 'day')
    : dayjs(endDateTime, inputFormat).tz(timezone);

  console.assert(!isEmpty(startDate), 'startDate is empty');
  console.assert(!isEmpty(endDate), 'endDate is empty');

  if (startDate.isAfter(endDate)) {
    [endDate, startDate] = [startDate, endDate];
  }

  const getDate = (
    dateTimeString: string,
    timezone: string = DEFAULT_TIMEZONE,
    inputFormat: string = DEFAULT_FORMAT
  ): dayjs.Dayjs => {
    return dayjs(dateTimeString, inputFormat).tz(timezone);
  };

  const isSameYear = startDate.year() === endDate.year();
  const isSameDay =
    startDate.year() === endDate.year() && startDate.month() === endDate.month() && startDate.day() === endDate.day();
  const isMultiDay = !isSameDay;

  const isBeforeRange = (date: dayjs.Dayjs): boolean => {
    if (!date || !startDateTime) return false;
    return date.isBefore(startDate);
  };

  const isAfterRange = (date: dayjs.Dayjs): boolean => {
    if (!date || !endDateTime) return false;
    const result = date.isAfter(endDate);
    return result;
  };

  const isWithinRange = (date: dayjs.Dayjs): boolean => {
    return date.isBefore(endDateTime) && date.isAfter(startDateTime);
  };

  const isOutsideRange = (date: dayjs.Dayjs): boolean => {
    return isBeforeRange(date) || isAfterRange(date);
  };

  const currentlyWithinRange = (): boolean => {
    const now = dayjs().tz(timezone);
    return isWithinRange(now);
  };

  return {
    getDate,
    isSameYear,
    isSameDay,
    isMultiDay,
    isWithinRange,
    isBeforeRange,
    isAfterRange,
    isOutsideRange,
    currentlyWithinRange
  };
};

export const isSaleTimeTicket = (startDateTime: string, endDateTime: string, timezone: string = DEFAULT_TIMEZONE) => {
  if (isEmpty(startDateTime) && isEmpty(endDateTime)) return true;
  const now = dayjs();

  const isAfterStartDate = isEmpty(startDateTime) || now.isAfter(dayjs(startDateTime, DEFAULT_FORMAT).tz(timezone));
  const isBeforeEndDate = isEmpty(endDateTime) || now.isBefore(dayjs(endDateTime, DEFAULT_FORMAT).tz(timezone));

  return isAfterStartDate && isBeforeEndDate;
};

export const isValidDate = (date: Date | string | null) => !isEmpty(date) && dayjs(date).isValid();
