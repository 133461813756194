// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

export const DigitalPass = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='none' {...props}>
    <Rect width={55.67} height={39.869} x={4.17} y={12.07} stroke='#000' strokeWidth={3} rx={2.5} />
    <Path fill='#000' d='M5.67 13.57h15.8c-6.949 0-12.287 6.155-11.304 13.035L13.57 50.44h-7.9V13.57Z' />
    <Rect width={24.835} height={1.5} x={16.953} y={19.587} fill='#000' stroke='#000' strokeWidth={1.5} rx={0.75} />
    <Rect width={35.369} height={1.5} x={16.953} y={26.716} fill='#000' stroke='#000' strokeWidth={1.5} rx={0.75} />
    <Rect width={14.301} height={1.5} x={16.953} y={42.451} fill='#000' stroke='#000' strokeWidth={1.5} rx={0.75} />
  </Svg>
);
