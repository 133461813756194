import dayjs from 'dayjs';
import { Linking } from 'react-native';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import {
  Event,
  EventCombined,
  EventSeason,
  GetStartDateTitleProps,
  InformationFormField,
  ProductSeating,
  School,
  Season
} from '@gf/cross-platform-lib/interfaces';
import { AMERICA_NEW_YORK, DATE_WITH_TIME_ZONE_FORMAT, getUnixTime } from './dateUtils';
import { isEmpty } from './isEmpty';
import {
  DATE_TIME_FORMAT,
  FULL_DATE_FORMAT,
  MONTH_DAY_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
  SHORT_MONTH_DAY_YEAR_FORMAT,
  MONTH_DAY_YEAR_TIME_FORMAT,
  NONLEVEL_GENDER_EVENT_TYPE,
  PLAYOFFS_VALUES
} from '../constants';
import uniq from 'lodash/uniq';
import { FilterURLType } from '../utils/filterUtils';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { Base64 } from './encoder';
import { getTicketBackgroundTextColors } from './colors';
import { ActivityName, getActivityIconUrl } from './icons';
import { EventCart } from '../models';
import { GetCurrentApplicationConfiguration } from './config';

// TO DO: Should these be added to `useEventInfo`?

function isFeaturedEvent(schoolsFeaturedOn: string[], schoolId: string) {
  return schoolsFeaturedOn && schoolsFeaturedOn.length > 0 && schoolsFeaturedOn.indexOf(schoolId) > -1;
}

function getSeasonScheduledInfo(eventItem: EventSeason) {
  return isEmpty(eventItem.eventIds)
    ? 'No Events Scheduled Yet'
    : `${eventItem.eventIds!.length} ${eventItem.eventIds!.length > 1 ? ' Events' : 'Event'} Scheduled`;
}

function getEventInvolvedSchools(eventItem: EventSeason, taggedSchools: School[] = []) {
  let attendedSchools: School[] = [];
  if (eventItem.school && !isEmpty(eventItem.school)) {
    attendedSchools = [eventItem.school!];
  }
  if (eventItem.opponentSchool && !isEmpty(eventItem.opponentSchool)) {
    attendedSchools = [...attendedSchools, eventItem.opponentSchool];
  }
  if (taggedSchools && !isEmpty(taggedSchools)) {
    attendedSchools = [...attendedSchools, ...taggedSchools];
  }
  if (!isEmpty(eventItem.school || {}) || !isEmpty(eventItem.opponentSchool || {}) || taggedSchools.length > 0) {
    const result: School[] = attendedSchools
      .filter(
        (schl: School) =>
          !isEmpty(schl) &&
          schl.gofanPageEnabled &&
          schl.searchEnabled &&
          schl.industryCode !== 'State Association' &&
          schl.industryCode !== 'School Board'
      )
      .sort((a, b) => (a?.id === eventItem?.accountId ? -1 : b?.id === eventItem?.accountId ? 1 : 0));

    return result;
  }
}

const getLevelGenderTitle = (
  eventTypeName: string,
  levels: { genders: string[]; levelName: string }[],
  genders: string[],
  title: string
) => {
  if (NONLEVEL_GENDER_EVENT_TYPE.includes(eventTypeName)) {
    return '';
  }
  if (!isEmpty(levels)) {
    const boyLevels: string[] = [];
    const girlLevels: string[] = [];
    const coedLevels: string[] = [];
    levels.forEach(level => {
      const listLevelName = level.levelName.split(' ');
      const abbreviationLevel =
        listLevelName.length === 1 ? level.levelName : listLevelName.map(item => item[0]).join('');
      if (isEmpty(level.genders)) {
        coedLevels.push(abbreviationLevel);
      }
      if (level.genders.includes('Boys')) {
        boyLevels.push(abbreviationLevel);
      }
      if (level.genders.includes('Girls')) {
        girlLevels.push(abbreviationLevel);
      }
    });
    const levelTitle: string[] = [];
    !isEmpty(coedLevels) && levelTitle.push(coedLevels.join('/'));
    !isEmpty(boyLevels) && levelTitle.push(`Boys ${boyLevels.join('/')}`);
    !isEmpty(girlLevels) && levelTitle.push(`Girls ${girlLevels.join('/')}`);
    if (!isEmpty(genders)) {
      const missingGenders = genders.filter(gender => !levelTitle.join().includes(gender));
      !isEmpty(missingGenders) && levelTitle.push(missingGenders.join(','));
    }
    return levelTitle.join(' & ');
  }
  if (!isEmpty(genders)) {
    return genders.join(' & ');
  }
  return title || '';
};

const mapToEventSeason = (event?: Event, season?: Season): EventSeason => {
  const eventOrSeason = event ?? season;
  const id = eventOrSeason?.id ?? 'no-event-or-season-id';
  const name = season?.name ?? event?.title ?? '';
  const type = event?.activity?.name ?? '';
  const isSeason = season ? true : false;
  return {
    //todo: define more
    ...eventOrSeason,
    id,
    name,
    type,
    isSeason,
    startDate: event?.startDate ?? '',
    startDateTime: event?.startDateTime ?? season?.startDateTime ?? '',
    endDate: event?.endDate ?? '',
    endDateTime: event?.endDateTime ?? season?.endDateTime ?? '',
    timeZone: season?.timeZone ?? event?.timeZone ?? '',
    schoolsFeaturedOn: event ? event.schoolsFeaturedOn : [],
    taggedSchoolHuddleIds: event ? event.taggedSchoolHuddleIds : [],
    genders: eventOrSeason ? eventOrSeason.genders : [],
    isAllDayEvent: event ? event.isAllDayEvent : false,
    optionalTypeDescription: event ? event.optionalTypeDescription : '',
    ticketTypes: event ? event.ticketTypes : []
  };
};

function isSameDayEvent(startDateTime: string, endDateTime: string, timeZone: string) {
  const startDate = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT);
  const endDate = getUnixTime(endDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT);
  return startDate.isSame(endDate, 'day');
}

function getDateRange(event: EventSeason | EventCombined): string {
  if (!dayjs(event.startDateTime).isValid() || !dayjs(event.endDateTime).isValid()) return 'Invalid date';
  const startDateTitle = getUnixTime(event.startDateTime, event.timeZone, DATE_WITH_TIME_ZONE_FORMAT).format('MMM D');
  const endDateTitle = getUnixTime(event.endDateTime, event.timeZone, DATE_WITH_TIME_ZONE_FORMAT).format('MMM D');
  return `${startDateTitle} - ${endDateTitle}`;
}

function getDateRangeWithYear(event: EventSeason | EventCombined): string {
  if (!dayjs(event.startDateTime).isValid() || !dayjs(event.endDateTime).isValid()) return 'Invalid date';
  const startDateTitle = getUnixTime(event.startDateTime, event.timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(
    'MMM D, YYYY'
  );
  const endDateTitle = getUnixTime(event.endDateTime, event.timeZone, DATE_WITH_TIME_ZONE_FORMAT).format('MMM D, YYYY');
  return `${startDateTitle} - ${endDateTitle}`;
}

function isSameYearEvent(startDateTime: string, endDateTime: string, timeZone: string) {
  const startDate = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT);
  const endDate = getUnixTime(endDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT);
  return startDate.isSame(endDate, 'year');
}

const getTicketsEventTime = ({
  isAllDayEvent,
  startDateTime: eventStartDateTime,
  endDateTime: eventEndDateTime,
  timeZone
}: Partial<Event>): string => {
  if (isAllDayEvent) {
    return `${eventStartDateTime && dayjs(eventStartDateTime).tz(timeZone).format('MMM DD, YYYY')} - ${
      eventEndDateTime && dayjs(eventEndDateTime).tz(timeZone).format('MMM DD, YYYY')
    }`;
  }
  if (eventStartDateTime && eventEndDateTime) {
    return `${dayjs(eventStartDateTime).tz(timeZone).format('ddd. MMM DD, YYYY')}`;
  }
  return '';
};

export const getFormattedStartDate = ({
  startDateTime,
  endDateTime,
  timeZone,
  isAllDayEvent,
  isSeason,
  hideTimeStamp: hideTimeStamp = false
}: GetStartDateTitleProps): string => {
  const isSameDay = isSameDayEvent(startDateTime, endDateTime, timeZone);

  const startDateFD = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(FULL_DATE_FORMAT);
  const startDateMDY = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(MONTH_DAY_YEAR_FORMAT);
  const startDateMD = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(MONTH_DAY_FORMAT);

  const startDateMDYT = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(
    MONTH_DAY_YEAR_TIME_FORMAT
  );

  const endDateMDY = getUnixTime(endDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(MONTH_DAY_YEAR_FORMAT);

  let startDate = '';

  if (startDateTime) {
    if (isSeason) {
      startDate = isSameDay ? startDateMDY : `${startDateMDY} - ${endDateMDY}`;
    } else if (isAllDayEvent) {
      startDate = isSameDay ? `${startDateFD} • All day` : `${startDateMDY} - ${endDateMDY}`;
    } else {
      startDate = isSameDay
        ? hideTimeStamp
          ? startDateMDY
          : startDateMDYT
        : hideTimeStamp
        ? `${startDateMD} - ${endDateMDY}`
        : `${startDateMDYT} - ${endDateMDY}`;
    }
  }

  return startDate;
};

export const getValidParams = (query: FilterURLType) => {
  const keys = Object.keys(query).filter(params => params.trim().toLowerCase() === params);

  const validFilterArray = keys.map(k => ({ [k]: Array.isArray(query[k]) ? '' : query[k] }));

  return Object.assign({}, ...validFilterArray);
};

export const checkEmptyValidFilters = (selectedFilter: FilterURLType) =>
  Object.values(selectedFilter).some(value => !isEmpty(value));

export const getValidFilterPairs = (filterQuery: FilterURLType, schoolId: string, listCategories: any) => {
  const { activity, level, gender, location, playoffs, school } = getValidParams(filterQuery);
  const {
    activities: allActivities = [],
    levels: allLevels = [],
    genders: allGenders = [],
    playOff,
    schools,
    searchOptions = []
  } = listCategories || {};

  let activities: Set<string> = new Set();
  let schoolFilterValue = '';
  let levels: Set<string> = new Set();
  let genders: Set<string> = new Set();
  let homeAwayValue: Set<string> = new Set();
  let playoffsValue = '';

  const hasHomeAwayEvents = searchOptions.length > 1;

  const hasPlayoffsEvents = playOff;

  const capitalize = (str = '') => startCase(toLower(str));

  if (activity) {
    const existingActivities = allActivities.map((act: any) => act.customSportName || act.name || '');
    const existingFilter: string[] = uniq([...existingActivities]);
    const splitActivities = activity.split(',').map((v: string) => v.trim().toLowerCase());
    splitActivities?.forEach((filter: string) => {
      const foundActivity = existingFilter.find(f => f.toLowerCase() === filter);
      if (foundActivity) {
        activities.add(foundActivity);
      }
    });
  }

  //Filter for home or away or home and away
  if (!isEmpty(location) && hasHomeAwayEvents) {
    const splitHomeAway = location.split(',').map((v: string) => capitalize(v.trim()));
    splitHomeAway?.forEach((filter: string) => {
      if (searchOptions.includes(filter.toLowerCase())) {
        homeAwayValue.add(filter);
      }
    });
  }

  if (!isEmpty(school)) {
    const trimmedValue = school.trim().toUpperCase();
    const schoolExists: boolean = schools.includes(trimmedValue);
    if (schoolExists) {
      schoolFilterValue = trimmedValue;
    }
  }

  if (!isEmpty(level)) {
    const splitLevel = level.split(',').map((v: string) => capitalize(v.trim()));

    splitLevel?.forEach((filter: string) => {
      if (allLevels.includes(filter)) {
        levels.add(filter);
      }
    });
  }

  if (!isEmpty(gender)) {
    const splitGender = gender.split(',').map((v: string) => capitalize(v.trim()));

    splitGender?.forEach((filter: string) => {
      if (allGenders.includes(filter)) {
        genders.add(filter);
      }
    });
  }

  if (!isEmpty(playoffs) && hasPlayoffsEvents) {
    const trimmedValue = playoffs.trim().toLowerCase();

    playoffsValue = PLAYOFFS_VALUES.includes(trimmedValue) ? trimmedValue : '';
  }

  return {
    activities: Array.from(activities),
    levels: Array.from(levels, levelName => ({ levelName })),
    gendersValue: Array.from(genders),
    homeAway: Array.from(homeAwayValue),
    playoffsValue,
    schoolFilterValue,
    isEmptyFilterParams:
      isEmpty(Array.from(activities)) &&
      isEmpty(Array.from(levels)) &&
      isEmpty(Array.from(genders)) &&
      isEmpty(Array.from(homeAwayValue)) &&
      isEmpty(playoffsValue) &&
      isEmpty(schoolFilterValue)
  };
};

export const getEventActivityInfo = (event: Event, separator = '-') => {
  const activityName = event.eventTypeName || event.activity?.name || '';
  let activityTitle = activityName;
  const eventTypeDescription = getLevelGenderTitle(
    event.eventTypeName ?? '',
    event.levels ?? [],
    event.genders,
    event.optionalTypeDescription
  );
  if (eventTypeDescription) {
    activityTitle = `${activityName} ${separator} ${eventTypeDescription}`;
  }
  return {
    activityName,
    activityTitle
  };
};

export const getSeatLabelFormatted = (seatsIoLabel: string | undefined) => {
  if (!seatsIoLabel) return '';

  const splitSeatsLabels = seatsIoLabel.split('-');

  if (splitSeatsLabels.length < 2) return '';

  let label = '';
  const seat = splitSeatsLabels.pop();
  const row = splitSeatsLabels.pop();
  if (splitSeatsLabels.length > 0) {
    const section = splitSeatsLabels.join('-');
    label += `Section ${section}, `;
  }

  label += `Row ${row}, Seat ${seat}`;

  return label;
};

export const getTicketUseTransferTime = ({
  timeZone = AMERICA_NEW_YORK,
  usedAt,
  transferredAt
}: {
  timeZone: string;
  usedAt?: string | null;
  transferredAt?: string | null;
}) => {
  if ((!usedAt && !transferredAt) || !timeZone) return null;
  return `${dayjs(transferredAt).tz(timeZone).format(DATE_TIME_FORMAT)}`;
};

export enum TicketState {
  'AVAILABLE',
  'USED',
  'TRANSFERRED',
  'REFUNDED',
  'TRANSFER_UNAVAILABLE'
}
export interface IVenue {
  city?: string;
  location?: string;
  locationName?: string;
  name?: string;
  state?: string;
  streetAddress?: string;
  streetAddressTwo?: string | null;
  zip?: string;
}
export interface IEventInfo {
  eventId: string;
  title: string;
  activityName: string;
  activityTitle: string;
  activityIcon?: string;
  eventDateTime: string;
  timezone?: string;
  venue?: IVenue;
  alertMessage?: string;
  startDate?: string;
}

export interface IOrderInfo {
  email: string;
  orderId: number;
}

export interface ITicketInfo {
  id: number;
  name: string;
  accessToken: string;
  additionalForm?: {
    [key: string]: string;
  };
  ticketState: TicketState;
  useTransferTime?: string | null;
  seatInfo?: string;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
  qrCodeSrc?: string;
  receiptEmail: string;
  oderId: number;
}

export interface ISchoolInfo {
  name?: string;
  logoUrl?: string;
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface ITicketToScanQuery {
  schoolInfo: ISchoolInfo;
  tickets?: ITicketInfo[];
  accessTokens?: string[];
  eventInfo: IEventInfo;
  orderInfo: IOrderInfo;
}

type GetTicketsScanInfoResponse = {
  eventId: string;
  schoolId: string;
  ticketIds: string[];
  data: {
    eventInfo: ITicketToScanQuery['eventInfo'];
    schoolInfo: ITicketToScanQuery['schoolInfo'];
    orderInfo: ITicketToScanQuery['orderInfo'] | null;
    accessTokens: string[];
  };
  encodedData: string | null;
};

export const getTicketsScanInfo = async ({
  event,
  school,
  accessTokens,
  orderInfo
}: {
  event: Event;
  school: School;
  accessTokens: string[];
  orderInfo: ITicketToScanQuery['orderInfo'] | null;
}): Promise<GetTicketsScanInfoResponse> => {
  const { activityName, activityTitle } = getEventActivityInfo(event, '•');
  const activityIcon = getActivityIconUrl(activityName.replace(/ /g, '').toLowerCase() as ActivityName);
  const eventInfo: ITicketToScanQuery['eventInfo'] = {
    eventId: event.id,
    title: event.title!,
    activityTitle,
    activityName,
    activityIcon,
    eventDateTime: getFormattedStartDate({
      startDateTime: event.startDateTime,
      endDateTime: event.endDateTime,
      timeZone: event.timeZone,
      isAllDayEvent: event.isAllDayEvent,
      isSeason: event.isSeason || false
    }),
    venue: event.venue,
    alertMessage: event.alert,
    startDate: getUnixTime(event.startDateTime, event.timeZone, DATE_WITH_TIME_ZONE_FORMAT).format(
      SHORT_MONTH_DAY_YEAR_FORMAT
    )
  };

  const schoolInfo: ITicketToScanQuery['schoolInfo'] = {
    name: school.name,
    logoUrl: school.logoUrl,
    ...getTicketBackgroundTextColors(school.primaryColor)
  };

  const data = {
    eventInfo,
    schoolInfo,
    orderInfo,
    accessTokens
  };

  let encodedData = null;
  try {
    encodedData = Base64.btoa(encodeURIComponent(JSON.stringify(data)));
  } catch (err) {
    console.error('Error encoding data', encodedData);
  }
  return {
    eventId: event.id,
    schoolId: school.id,
    ticketIds: [] as string[],
    data,
    encodedData
  };
};

export const getUniqueURL = (path = '') => `p/${path}`;

export const getFormFieldValues = (event?: EventCombined | null | undefined) => {
  let formFieldValues: InformationFormField[] | [] = [];
  if (event && !isEmpty(event)) {
    formFieldValues = event.form?.formFields || [];
    event.ticketTypes.forEach(item => {
      formFieldValues = isEmpty(formFieldValues)
        ? item.form?.formFields || []
        : [...formFieldValues, ...(item.form?.formFields || [])];
    });
  }
  return formFieldValues;
};

export const reachedLimit = (total: number, limit: number, packCount: number) => {
  if (!limit) return false;
  return packCount > 1 ? (limit - total) / packCount < 1 : limit - total <= 0;
};

export const convertEventSalesInfo = (event: EventCart, ticket: ProductSeating, isSeason: boolean) => {
  const { eventSalesInfo } = event || {};
  const { seasonTotalRemainingQuantity, eventTotalRemainingQuantity, seasonSoldOut, eventSoldOut, productSalesMap } =
    eventSalesInfo || {};
  const ticketSalesInfo = productSalesMap[ticket.id];
  return {
    ...event,
    eventSalesInfo: {
      ...eventSalesInfo,
      ticketLimitPerOrder: event.ticketLimitPerOrder,
      eventTotalRemainingQuantity:
        isSeason && seasonTotalRemainingQuantity ? seasonTotalRemainingQuantity : eventTotalRemainingQuantity,
      eventSoldOut: isSeason ? seasonSoldOut : eventSoldOut,
      productSalesMap: {
        ...productSalesMap,
        [ticket.id]: {
          ...ticketSalesInfo,
          remainingQuantity:
            ticket?.ticketLimitPerOrder && ticket?.ticketLimitPerOrder < ticketSalesInfo.remainingQuantity
              ? ticket?.ticketLimitPerOrder
              : ticketSalesInfo.remainingQuantity
        }
      }
    }
  };
};

export const getSchoolDistrictTypeName = (schoolTypesToSchools: string[]) =>
  schoolTypesToSchools
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .map(schoolType => schoolType.toString() && schoolType.charAt(0).toUpperCase() + schoolType.slice(1) + 's')
    .join(', ');

export const printPdfFile = ({ ticketIds, page = '' }: { ticketIds: string[]; page?: string }) => {
  let encodeTicketIds = null;
  try {
    encodeTicketIds = Base64.btoa(encodeURIComponent(ticketIds.join(',')));
  } catch (err) {
    console.error('Error encoding data', ticketIds);
  }
  encodeTicketIds &&
    Linking.openURL(
      GetCurrentApplicationConfiguration().origin.urlWeb +
        GOFAN_APP_PAGES.downloadTickets.getPath('ticketIds', `${encodeTicketIds}&page=${page}`)
    );
};

export const roundingDecimalNumber = (num: number, abs?: boolean) => {
  if (abs) {
    return parseFloat(Math.abs(Number(num)).toFixed(2));
  }
  return parseFloat(Number(num).toFixed(2));
};

const getStartTimeTitle = ({
  allDayEvent,
  eventStartDateTime,
  timeZone
}: {
  allDayEvent: boolean | undefined;
  eventStartDateTime: string | null | undefined;
  timeZone: string;
}) => {
  let startTimeTitle = '';
  if (allDayEvent) {
    startTimeTitle = 'All day';
  } else if (eventStartDateTime) {
    startTimeTitle = `at ${dayjs(eventStartDateTime).tz(timeZone).format('h:mm A')}`;
  }
  return startTimeTitle;
};

export {
  isFeaturedEvent,
  getSeasonScheduledInfo,
  getEventInvolvedSchools,
  getLevelGenderTitle,
  mapToEventSeason,
  isSameDayEvent,
  getDateRange,
  getDateRangeWithYear,
  isSameYearEvent,
  getTicketsEventTime,
  getStartTimeTitle
};
